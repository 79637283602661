import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import '../fragments';

import { localize } from '../utils';
import { DEFAULT_LANG } from '../constants';
import SEO from '../components/seo';
import Layout from '../components/Layout';

const GalleryPageTemplate = ({ data, lang }) => {
  const langs = [lang];
  if (lang !== DEFAULT_LANG) {
    langs.push(DEFAULT_LANG);
  }

  const gallery = localize(data.sanityGallery, langs);

  useEffect(() => {
    console.log('YYYYYYYYYYY');
    function initGallery() {
      console.log('XXXXXXXXXX');
      const containers = document.querySelectorAll('.images-container');
      for (let i = 0; i < containers.length; i++) {
        (function(container) {
          const msnry = new window.Masonry(container, {
            // options
            // columnWidth: 285,
            itemSelector: '.item',
            gutter: 15,
          });
          const intervalId = setInterval(function() {
            msnry.layout();
          }, 200);
          window.imagesLoaded(container, function() {
            msnry.layout();
            clearInterval(intervalId);
          });
        })(containers[i]);
      }

      window.$('.images-container').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
          enabled: true,
        },
        image: {
          titleSrc: function(item) {
            return item.el.attr('title');
          },
        },
      });
    }
    const initGalleryInterval = setInterval(() => {
      if (window.Masonry && window.imagesLoaded && window.$ && window.$.magnificPopup) {
        clearInterval(initGalleryInterval);
        initGallery();
      }
    }, 100);
  }, []);

  return (
    <Layout>
      <SEO langs={langs} />
      <div className="gallery">
        <h3>
          {gallery.title}
          <Link to="/galeria" className="back_to_gallery">
            Voltar à galeria
          </Link>
        </h3>
        {gallery.products
          .filter(product => product.active)
          .map((product, i) => (
            <div key={i}>
              {product.descriptions.map((description, j) => (
                <h2 key={`description-${i}-${j}`} className="gallery_subsection_header">
                  {description.name}{' '}
                  {description.price && (
                    <span className="gallery_price">({description.price})</span>
                  )}
                </h2>
              ))}
              <div key={`images-container-${i}`} className="images-container">
                {product.images.map((image, j) => (
                  <div key={`image-${i}-${j}`} className={'item ' + (image.layout || '')}>
                    <a
                      href={
                        image.image.asset.url +
                        '?fit=max&w=' +
                        ((image.layout === 'panorama' && 1450) ||
                          (image.layout === 'horizontal' && 1200) ||
                          700)
                      }
                    >
                      <img
                        src={
                          image.image.asset.url +
                          '?fit=crop&w=' +
                          ((image.layout === 'panorama' && 880) ||
                            (image.layout === 'horizontal' && 440) ||
                            285) +
                          '&h=' +
                          ((image.layout === 'panorama' && 376) ||
                            (image.layout === 'horizontal' && 326) ||
                            380)
                        }
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          ))}
      </div>
    </Layout>
  );
};

GalleryPageTemplate.defaultProps = {
  lang: DEFAULT_LANG,
};

GalleryPageTemplate.propTypes = {
  lang: PropTypes.string,
};

export const query = graphql`
  query($id: String!) {
    sanityGallery(id: { eq: $id }) {
      title
      products {
        active
        descriptions {
          name
          price
        }
        images {
          layout
          image {
            asset {
              url
            }
          }
        }
      }
    }
  }
`;

export default GalleryPageTemplate;
